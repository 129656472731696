import { CONSTANTS_ACTIONS } from "constants/ConstantsActions";
import { CONSTANTS_MESSAGE } from "constants/ConstantsMessages";
import {
  actionActiveLoading,
  actionDesactiveLoading,
  actionDispatch,
  actionMessage,
  actionSetResponseCode,
} from "redux/actions/SharedActions";
import {
  ActiveClient,
  CancelRequest,
  GetAmountOfClientsByFee,
  ChangeClientsFee,
  GetClient,
  GetClients,
  GetClientsSend,
  GetClientsFullList,
  GetClientsPerDate,
  SaveClient,
  UnsubscribeClient,
  UpdateClient,
  GenerateClientsFees,
  GetClientsBilling,
  GenerateClientsCardFees,
  GetClientsSuggestion,
  GetClientsBillingSuggestion,
  UpdateUnsubscribeClient,
  DeleteConyuge
} from "redux/services/ClientServices";
import { actionGetUnsubscriptionRequest, actionGetUnsubscription } from "./ReportsActions";
import { actionNavigateToClients } from "redux/actions/SharedActions";

/**
 * Funcion que toma todos los clientes del sistema.
 *
 * @param {number} nPage Numero de pagina que se intenta obtener
 * @param {number} nClientsPerPage Numero de clientes por paginas
 *
 * @author Leandro Curbelo
 */
export const actionGetClients = (
  nPage = 0,
  nClientsPerPage = 10,
  sSearch = ""
) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    GetClients(nPage, nClientsPerPage, sSearch)
      .then((oSuccess) => {
          console.log("Respuesta exitosa de GetClientsSend:", oSuccess);
        debugger; 
        dispatch(actionDispatch(CONSTANTS_ACTIONS.CLIENTS, oSuccess.data));
      })
      .catch((oError) => {
        if (oError.response) {
          dispatch(actionSetResponseCode(oError.response.status));
        }
        dispatch(actionDispatch(CONSTANTS_ACTIONS.CLIENTS, []));
        dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
      })
      .finally(() => {
        dispatch(actionDesactiveLoading());
      });
  };
};


/**
 * Funcion que busca los clientes con cambio de bateria pendiente para una fecha dada.
 *
 *
 * @param {date} fDate Numero de clientes por paginas
 *
 * @author Mauricio Rodriguez
 */
export const actionGetClientsSend = (fDate = '') => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    GetClientsSend(fDate)
      .then((oSuccess) => {
       
        dispatch(actionDispatch(CONSTANTS_ACTIONS.CLIENTS_SEND, oSuccess.data));
      })
      .catch((oError) => {
        if (oError.response) {
          dispatch(actionSetResponseCode(oError.response.status));
        }
        dispatch(actionDispatch(CONSTANTS_ACTIONS.CLIENTS_SEND, []));
        dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
      })
      .finally(() => {
        dispatch(actionDesactiveLoading());
      });
  };
};


export const actionGetClientsAutosuggest = (sSearch = null) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    GetClientsSuggestion(sSearch).then((oSuccess) => {
      dispatch(actionDispatch(CONSTANTS_ACTIONS.CLIENTS_AUTOSUGGEST, oSuccess.data));
    }).catch(() => {
      dispatch(actionDispatch(CONSTANTS_ACTIONS.CLIENTS_AUTOSUGGEST, []));
      dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
    }).finally(() => dispatch(actionDesactiveLoading()));
  };
};

export const actionGetClientsBillingAutosuggest = (sSearch = null) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    GetClientsBillingSuggestion(sSearch).then((oSuccess) => {
      dispatch(actionDispatch(CONSTANTS_ACTIONS.CLIENTS_AUTOSUGGEST, oSuccess.data));
    }).catch(() => {
      dispatch(actionDispatch(CONSTANTS_ACTIONS.CLIENTS_AUTOSUGGEST, []));
      dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
    }).finally(() => dispatch(actionDesactiveLoading()));
  };
};

export const actionCleanAutosuggest = () => {
  return (dispatch) => {
    dispatch(actionDispatch(CONSTANTS_ACTIONS.CLEAN_CLIENTS_AUTOSUGGEST));
  };
};

/**
 * Funcion que toma todos los clientes del sistema.
 *
 * @author Leandro Curbelo
 */
export const actionGetClientsFullList = () => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    GetClientsFullList()
      .then((oSuccess) => {
        dispatch(
          actionDispatch(CONSTANTS_ACTIONS.CLIENTS_FULL_LIST, oSuccess.data)
        );
      })
      .catch((oError) => {
        if (oError.response) {
          dispatch(actionSetResponseCode(oError.response.status));
        }
        dispatch(actionDispatch(CONSTANTS_ACTIONS.CLIENTS_FULL_LIST, []));
        dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
      })
      .finally(() => {
        dispatch(actionDesactiveLoading());
      });
  };
};
/**
 * Funcion que toma todos los clientes del sistema.
 *
 * @param {number} nPage Numero de pagina que se intenta obtener
 * @param {number} nClientsPerPage Numero de clientes por paginas
 *
 * @author Leandro Curbelo
 */
export const actionGeClientsPerDate = (
  nPage = 1,
  nClientsPerPage = 10,
  sSearch = "",
  sFilterDate = "",
  dStartDate = null,
  dEndDate = null
) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    GetClientsPerDate(
      nPage,
      nClientsPerPage,
      sSearch,
      sFilterDate,
      dStartDate,
      dEndDate
    )
      .then((oSuccess) => {
        dispatch(actionDispatch(CONSTANTS_ACTIONS.CLIENTS, oSuccess.data));
      })
      .catch((oError) => {
        if (oError.response) {
          dispatch(actionSetResponseCode(oError.response.status));
        }
        dispatch(actionDispatch(CONSTANTS_ACTIONS.CLIENTS, []));
        dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
      })
      .finally(() => {
        dispatch(actionDesactiveLoading());
      });
  };
};

export const actionGetClientsBilling = (
  nPage = 1,
  nClientsPerPage = 10,
  sSearch = ""
) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    GetClientsBilling(nPage, nClientsPerPage, sSearch)
      .then((oSuccess) => {
        dispatch(actionDispatch(CONSTANTS_ACTIONS.CLIENTS, oSuccess.data));
      })
      .catch((oError) => {
        if (oError.response) {
          dispatch(actionSetResponseCode(oError.response.status));
        }
        dispatch(actionDispatch(CONSTANTS_ACTIONS.CLIENTS, []));
        dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
      })
      .finally(() => {
        dispatch(actionDesactiveLoading());
      });
  };
};

/**
 * Funcion que obtiene un cliente del sistema.
 *
 * @param {number} nClientId ide del cliente
 *
 * @author
 */
export const actionGetClient = (nClientId) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    GetClient(nClientId)
      .then((oSuccess) => {
        dispatch(actionDispatch(CONSTANTS_ACTIONS.CLIENT, oSuccess.data));
      })
      .catch((oError) => {
        if (oError.response) {
          dispatch(actionSetResponseCode(oError.response.status));
        }
        dispatch(actionDispatch(CONSTANTS_ACTIONS.CLIENT, null));
        dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
      })
      .finally(() => {
        dispatch(actionDesactiveLoading());
      });
  };
};



export const actionResetClient = () => ({
  type: CONSTANTS_ACTIONS.RESET_CLIENT,
  /* return dispatch =>{
         dispatch(actionDispatch(CONSTANTS_ACTIONS.RESET_CLIENT)
     }*/
});

/**
 * Funcion que obtiene un cliente del sistema.
 *
 * @param {number} nClientId ide del cliente
 *
 * @author
 */

export const actionUnsubscribeClient = (nClientId, sReason, dDate, sObservation, { nPage = 1, nClientsPerPage = 10, sSearch = null } = {}) => {
  return (dispatch) => {
    UnsubscribeClient(nClientId, sReason, dDate, sObservation).then((oSuccess) => {
      dispatch(actionDispatch(CONSTANTS_ACTIONS.UNSUBSCRIBE_CLIENT, oSuccess.data));
      dispatch(actionGetClients(nPage, nClientsPerPage, sSearch));
    }).catch(oError => {
      let sMessage = oError.response.data ? oError.response.data.message : null;
      dispatch(actionDispatch(CONSTANTS_ACTIONS.UNSUBSCRIBE_CLIENT, { data: [], allRecords: 0 }));
      dispatch(actionMessage(sMessage));
    })
  };
};

export const actionUpdateUnsubscribeClient = (nUnsubscription, nClientId, sReason, dDate, sObservation, { nPage = 1, nClientsPerPage = 10, sSearch = null } = {}) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    UpdateUnsubscribeClient(nUnsubscription, nClientId, sReason, dDate, sObservation).then((oSuccess) => {
      dispatch(actionDispatch(CONSTANTS_ACTIONS.UNSUBSCRIBE_CLIENT, oSuccess.data));
      dispatch(actionGetClients(nPage, nClientsPerPage, sSearch));
    }).catch(oError => {
      let sMessage = oError.response.data ? oError.response.data.message : null;
      dispatch(actionDispatch(CONSTANTS_ACTIONS.UNSUBSCRIBE_CLIENT, { data: [], allRecords: 0 }));
      dispatch(actionMessage(sMessage));
    }).finally(() => dispatch(actionDesactiveLoading()));
  };
};

export const actionUpdateUnsubscribeRequestClient = (nUnsubscription, nClientId, sReason, dDate, sObservation, nPage, nClientsPerPage, sSearch) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    UpdateUnsubscribeClient(nUnsubscription, nClientId, sReason, dDate, sObservation).then((oSuccess) => {
      dispatch(actionGetUnsubscriptionRequest(nPage, nClientsPerPage, sSearch));
    }).catch(oError => {
      let sMessage = oError.response.data ? oError.response.data.message : null;
      dispatch(actionDispatch(CONSTANTS_ACTIONS.UNSUBSCRIBE_CLIENT, { data: [], allRecords: 0 }));
      dispatch(actionMessage(sMessage));
    }).finally(() => dispatch(actionDesactiveLoading()));
  };
};

export const actionContractClient = (nClientId) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    UnsubscribeClient(nClientId)
      .then((oSuccess) => {
        dispatch(
          actionDispatch(CONSTANTS_ACTIONS.CONTRACT_CLIENT, oSuccess.data)
        );
      })
      .catch((oError) => {
        if (oError.response) {
          dispatch(actionSetResponseCode(oError.response.status));
        }
        dispatch(actionDispatch(CONSTANTS_ACTIONS.CONTRACT_CLIENT, []));
        dispatch(actionMessage(CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR));
      })
      .finally(() => {
        dispatch(actionDesactiveLoading());
      });
  };
};

/**
 * Funcion que registra un  cliente en el sistema.
 *
 * @param {object} oData objeto cliente
 *
 * @author
 */

export const actionSaveClient = (oData) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    SaveClient(oData).then((oSuccess) => {
      dispatch(actionGetClient(oSuccess.client_id));
      dispatch(actionMessage("Guardado correctamente", CONSTANTS_MESSAGE.TYPE_SUCCESS));
      dispatch(actionDispatch(CONSTANTS_ACTIONS.CLIENT, oSuccess.data));
      dispatch(actionNavigateToClients())
    }).catch((oError) => {
      let sMessage = (oError && oError.response && oError.response.data) ? oError.response.data.message : CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR;
      dispatch(actionMessage(sMessage));
    }).finally(() => {
      dispatch(actionDesactiveLoading());
    });
  };
};

export const actionUpdateClient = (oData) => { 
  
  return (dispatch) => {
    dispatch(actionActiveLoading());
    UpdateClient(oData).then(() => {
      dispatch(actionGetClient(oData.id));
      dispatch(actionMessage("Actualizado correctamente", CONSTANTS_MESSAGE.TYPE_SUCCESS));
      dispatch(actionDispatch(CONSTANTS_ACTIONS.UPDATE_CLIENT));
      dispatch(actionNavigateToClients());
    }).catch((oError) => {
      let sMessage = (oError && oError.response && oError.response.data) ? oError.response.data.message : CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR;
      dispatch(actionMessage(sMessage));
    }).finally(() => {
      dispatch(actionDesactiveLoading());
    });
  }; 
};

export const actionCleanClient = () => {
  return (dispatch) => {
    dispatch(actionDispatch(CONSTANTS_ACTIONS.CLIENT, null));
  };
};

export const actionSetNavCurrentPageClients = (oData) => {
  return (dispatch) => {
    dispatch(
      actionDispatch(CONSTANTS_ACTIONS.SET_NAV_CURRENT_PAGE_CLIENTS, oData)
    );
  };
};

export const actionSetNavItemsPerPageClients = (oData) => {
  return (dispatch) => {
    dispatch(
      actionDispatch(CONSTANTS_ACTIONS.SET_NAV_ITEMS_PER_PAGE_CLIENTS, oData)
    );
  };
};

export const actionActiveClient = (nClientId, nPage, nClientsPerPage, sSearch) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    ActiveClient(nClientId).then((oSuccess) => {
      dispatch(actionMessage(oSuccess.message, CONSTANTS_MESSAGE.TYPE_SUCCESS));
      dispatch(actionGetUnsubscriptionRequest(nPage, nClientsPerPage, sSearch));
      dispatch(actionDispatch(CONSTANTS_ACTIONS.ACTIVE_CLIENT));
    }).catch(oError => {
      let sMessage = (oError && oError.response && oError.response.data) ? oError.response.data.message : CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR;
      dispatch(actionMessage(sMessage));
    });
  };
};

export const actionCancelRequest = (nClientId, nPage = 0, nClientsPerPage = 10, sSearch = "") => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    CancelRequest(nClientId)
      .then((oSuccess) => {
        dispatch(actionMessage("Solicitud eliminada", CONSTANTS_MESSAGE.TYPE_SUCCESS));
        dispatch(actionDispatch(CONSTANTS_ACTIONS.CANCEL_REQUEST));
        dispatch(actionGetUnsubscription(nPage, nClientsPerPage, sSearch));
      })
      .catch((oError) => {
        if (oError.response) { dispatch(actionSetResponseCode(oError.response.status)); }
        dispatch(actionMessage(oError));
      })
      .finally(() => {
        dispatch(actionDesactiveLoading());
      });
  };
};

export const actionGetAmountOfClientsByFee = (nfeeAmount, bMidesChangeFee) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    dispatch(actionDispatch(CONSTANTS_ACTIONS.CLIENTS_AMOUNT_BY_FEE, null));
    GetAmountOfClientsByFee(nfeeAmount, bMidesChangeFee)
      .then((oSuccess) => {
        if (oSuccess.data === 0) {
          dispatch(
            actionMessage(
              "No se encontró ningún cliente con esa cuota",
              CONSTANTS_MESSAGE.TYPE_ERROR
            )
          );
        } else {
          dispatch(
            actionDispatch(
              CONSTANTS_ACTIONS.CLIENTS_AMOUNT_BY_FEE,
              oSuccess.data
            )
          );
        }
      })
      .catch((oError) => {
        if (oError.response) {
          dispatch(actionSetResponseCode(oError.response.status));
        }
        dispatch(actionMessage(oError));
      })
      .finally(() => {
        dispatch(actionDesactiveLoading());
      });
  };
};

export const actionChangeClientsFee = (nLastFee, nNextFee, bMidesChangeFee) => {
  return (dispatch) => {
    //dispatch(actionActiveLoading());
    dispatch(actionDispatch(CONSTANTS_ACTIONS.CLIENTS_AMOUNT_BY_FEE, null));
    ChangeClientsFee(nLastFee, nNextFee, bMidesChangeFee)
      .then((oSuccess) => {
        dispatch(
          actionMessage(
            "Se actualizaron las cuotas correctamente",
            CONSTANTS_MESSAGE.TYPE_SUCCESS,
            false,
            setTimeout(() => window.location.reload(), 1500)
            
          )
        );
      })
      .catch((oError) => {
        if (oError.response) {
          dispatch(actionSetResponseCode(oError.response.status));
        }
        dispatch(actionMessage(oError));
      });
  };
};

export const actionGenerateClientsFees = (nPage, nClientsPerPage, sSearch) => {
   
  return (dispatch) => {
    dispatch(actionActiveLoading());
    GenerateClientsFees()
      .then((oSuccess) => {
        dispatch(actionGetClientsBilling(nPage, nClientsPerPage, sSearch));
        dispatch(
          actionMessage(
            "Se generaron las cuotas para los clientes.",
            CONSTANTS_MESSAGE.TYPE_SUCCESS,
            false
          )
        );
      })
      .catch((oError) => {
        dispatch(actionMessage(oError.response.message));
      });
  };
};
export const actionGenerateClientsCardFees = (nPage, nClientsPerPage, sSearch) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    GenerateClientsCardFees().then((oSuccess) => {
      dispatch(actionGetClientsBilling(nPage, nClientsPerPage, sSearch));
      dispatch(
        actionMessage(
          "Se generaron las cuotas para los clientes.",
          CONSTANTS_MESSAGE.TYPE_SUCCESS,
          false
        )
      );
    })
      .catch((oError) => {
        dispatch(actionMessage(oError.response.message));
      });
  };
};

export const actionDeleteConyuge = (nClientId, nPage, nClientsPerPage, sSearch) => {
  return (dispatch) => {
    dispatch(actionActiveLoading());
    DeleteConyuge(nClientId).then((oSuccess) => {
      dispatch(actionGetClients(nPage, nClientsPerPage, sSearch));
      dispatch(actionMessage("Se eliminó correctamente el cónyuge.", CONSTANTS_MESSAGE.TYPE_SUCCESS, false));
    })
      .catch((oError) => {
        dispatch(actionMessage(oError.response.message));
      });
  };
};

export const actionResetClients = () => ({
  type: CONSTANTS_ACTIONS.RESET_CLIENTS,
});

export const actionCleanClients = () => {
  return (dispatch) => {
    dispatch(actionDispatch(CONSTANTS_ACTIONS.CLEAN_CLIENTS));
  };
};