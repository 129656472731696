import { CONSTANTS_ACTIONS } from "constants/ConstantsActions";
import { CONSTANTS_PAGINATION } from "constants/ConstantsComponents";

const oInitialClient = {
  id: null,
  identificationcard: "",
  code: "",
  rut: "",
  name: "",
  secondname: "",
  lastname: "",
  secondsurname: "",
  affiliationdate: null,
  birthdate: null,
  sex: "Mujer",
  feevalue: 0,
  midespartner: null,
  mides: false,
  business: false,
  contacts: "",
  show_medicines: "",
  devicephone: "",
  observation: "",
  contract: false,
  active: true,
  company: {
    rut: "",
    name: "",
    domicile: "",
  },
  show_medical_societies: "",
  show_mobile_emergencies: "",
  show_private_doctor: "",
  show_device: "",
  change_battery_bracelet: null,
  change_battery: null,
  addresses: [],
  phones: [],
  emails: [],
  private_doctors: [],
  medical_records: [],
  spouse: null,
  jsbirthdate: null,
  jsaffiliationdate: null,
  jschangebattery: null,
  jsbatterybracelet: null,
  contracted_devices: [],
};

const aInitialState = {
  idClient: null,
  nAllRecords: null,
  aClients: {
    allRecords: 0,
    data: [],
  },
  aClientsFullList: [],
  oClientBoard: {
    id: null,
    identificationcard: "",
    code: "",
    rut: "",
    name: "",
    secondname: "",
    lastname: "",
    secondsurname: "",
    affiliationdate: null,
    birthdate: null,
    sex: "Mujer",
    feevalue: 0,
    midespartner: null,
    mides: false,
    business: false,
    contacts: "",
    show_medicines: "",
    devicephone: "",
    observation: "",
    contract: false,
    active: true,
    company: {
      rut: "",
      name: "",
      domicile: "",
    },
    show_medical_societies: "",
    show_mobile_emergencies: "",
    show_private_doctor: "",
    show_device: "",
    change_battery_bracelet: null,
    change_battery: null,
    addresses: [],
    phones: [],
    emails: [],
    private_doctors: [],
    medical_records: [],
    spouse: null,
    jsbirthdate: null,
    jsaffiliationdate: null,
    jschangebattery: null,
    jsbatterybracelet: null,
    contracted_devices: [],
  },
  oClient: oInitialClient,
  bStatus: false,
  bEdit: false,
  nNavCurrentPage: CONSTANTS_PAGINATION.nCurrentPage,
  nNavClientsPerPage: CONSTANTS_PAGINATION.nItemsPerPage,
  nClientsAmountByFee: null,
  aClientsAutosuggest: { allRecords: 0, data: [], }
};
/**
 * Reducer encargado de manejar todos los datos correspondiente a la parte de clientes.
 *
 * @param {Array} aState Estado del reducer, al momento de inicializarse se tomara aInitialState
 * @param {object} oAction Objeto donde se encuentra el tipo de estado que se desea actualizar y los respectivos datos
 */
export const clientsReducer = (aState = aInitialState, oAction) => {
  switch (oAction.type) {
    case CONSTANTS_ACTIONS.CLIENTS:
      return { ...aState, aClients: oAction.data, };
    case CONSTANTS_ACTIONS.CLIENTS_FULL_LIST:
      return { ...aState, aClientsFullList: oAction.data, };
    case CONSTANTS_ACTIONS.CLIENT:
      return { ...aState, oClient: oAction.data, oClientBoard: oAction.data ? oAction.data : aState.oClientBoard };
    case CONSTANTS_ACTIONS.CLIENTS_SEND:
      //console.log(oAction.data);
        return { ...aState, aClientsSend: oAction.data };
    case CONSTANTS_ACTIONS.SAVE_CLIENTS:
      return { ...aState, bStatus: true, };
    case CONSTANTS_ACTIONS.UPDATE_CLIENT:
      return { ...aState, bStatus: true, };
    case CONSTANTS_ACTIONS.ACTIVE_CLIENT:
      return { ...aState, bStatus: true, };
    case CONSTANTS_ACTIONS.CANCEL_REQUEST:
      return { ...aState, bStatus: true, };
    case CONSTANTS_ACTIONS.RESET_CLIENT:
      return { ...aState, bStatus: false, oClient: {}, };
    case CONSTANTS_ACTIONS.SET_NAV_CURRENT_PAGE_CLIENTS:
      return { ...aState, nNavCurrentPage: oAction.data, };
    case CONSTANTS_ACTIONS.SET_NAV_ITEMS_PER_PAGE_CLIENTS:
      return { ...aState, nNavClientsPerPage: oAction.data, };
    case CONSTANTS_ACTIONS.CLIENTS_AMOUNT_BY_FEE:
      return { ...aState, nClientsAmountByFee: oAction.data, };
    case CONSTANTS_ACTIONS.CLEAN_CLIENTS:
      return { ...aState, aClients: { allRecords: 0, data: [], }, };
    case CONSTANTS_ACTIONS.CLIENTS_AUTOSUGGEST:
      return { ...aState, aClientsAutosuggest: oAction.data };
    case CONSTANTS_ACTIONS.CLEAN_CLIENTS_AUTOSUGGEST:
      return { ...aState, aClientsAutosuggest: [] };
    case CONSTANTS_ACTIONS.RESET_RECEIPTS:
      return { ...aState, oClient: oInitialClient };
    default:
      return aState;
  }
};
