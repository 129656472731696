import React, { Component } from 'react';
import { Grid, Card, Form, Button } from 'tabler-react';
import { connect } from 'react-redux';
import { actionGetClientsSend } from 'redux/actions/ClientsActions';

class ClientSend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: '',  // Guardará la fecha seleccionada
    };
  }

  // Manejar el cambio en el campo de fecha
  handleDateChange = (event) => {
    this.setState({ selectedDate: event.target.value });
  };

  // Manejar la consulta
  handleConsult = () => {
    const { selectedDate } = this.state;
     
     this.props.get(selectedDate); // Llamar a la acción de Redux para obtener los datos
  };

  render() { 
    const { selectedDate } = this.state;
    const { clientsData } = this.props; // Datos de clientes desde Redux
    console.log(this.props);
    return (
      <Card>
        <Card.Header>
          <Card.Title>Formulario de Consulta Cambios Baterias</Card.Title>
        </Card.Header>
        <Card.Body>
          <Grid.Row>
            <Grid.Col width={12}>
              <Form>
                {/* Campo de fecha */}
                <Form.Group>
                  <Form.Label>Selecciona una fecha (La búsqueda se realizará en el año hasta la fecha ingresada)</Form.Label>
                  <Form.Input
                    type="date"
                    value={selectedDate}
                    onChange={this.handleDateChange}
                     className="w-25"
                  />
                </Form.Group>

                {/* Botón de consulta */}
                <Button type="button" color="primary" onClick={this.handleConsult}>
                  Consultar
                </Button>
              </Form>
            </Grid.Col>
          </Grid.Row>

          {/* Tabla con los datos de los clientes */}
          <Grid.Row style={{ marginTop: '20px' }}>
            <Grid.Col width={12}>
              <Card>
                <Card.Body>
                  <h4>Lista de Clientes</h4>
                  <table  cellPadding="10" style={{ width: '100%', marginTop: '20px' }}>
                    <thead>
                      <tr>
                        <th>Numero socio</th>
                        <th>Nombre</th>
                        <th>Apellido</th>
                        <th>Modelo Dispositivo</th>
                        <th>Fecha ultimo cambio bateria</th>
                        <th>WhatsApp notificación cambio batería</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clientsData && clientsData.length > 0 ? (
                        clientsData.map((client) => (
                          <tr >
                            <td>{client.code}</td>
                            <td>{client.name} {client.secondname || ''}</td>
                            <td>{client.lastname} {client.secondsurname || ''}</td>
                            <td>{client.device_model_name}</td>
                            <td>{client.changebatterybracelet}</td>
                            <td>{client.notification_change_battery || 'Sin Datos'}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5">No hay clientes disponibles</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </Card.Body>
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
      </Card>
    );
  }
}

// Mapear el estado de Redux a las propiedades del componente
const mapStateToProps = (state) => ({
 
  clientsData: state.clientsReducer.aClientsSend, 
});

// Mapear las acciones de Redux
const mapDispatchToProps = (dispatch) => ({
  get: (date) => {
   // fetch('http://127.0.0.1:8000/clients/sendWhatsappChangeBeteries/');
    dispatch(actionGetClientsSend(date)); // Llamar a la acción Redux con la fecha seleccionada
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientSend);

